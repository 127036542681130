// Formatar número de telefone
export const formatPhoneNumber = (str) => {
    return str
        .replace(/\D/g, '')
        .replace(/(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
            (fullMatch, country, ddd, dddWithZero, prefixTel, suffixTel) => {
                if (country) return `${ country } (${ ddd || dddWithZero }) ${ prefixTel }-${ suffixTel}`;
                if (ddd || dddWithZero) return `(${ ddd || dddWithZero }) ${ prefixTel }-${ suffixTel}`;
                if (prefixTel && suffixTel) return `${ prefixTel }-${ suffixTel }`;
                return str;
            }
        );
}

// Formatar data de nascimento
export const formatBirthDate = (str) => {
    //yyyy-mm-dd
    const year = str.substring(0,4);
    const month = str.substring(5,7);
    const day = str.substring(8);
    return `${day}/${month}/${year}`;
}

